import React from "react"
import Layout from "../../layouts/default"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Checkbox from "../../components/checkbox"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Inline from "../../components/inline"
import PageTitle from "../../components/page-title"
import PaperShred from "../../components/paper-shred"
import Paragraph from "../../components/paragraph"
import SectionSpacing from "../../components/section-spacing"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import StepsBox from "../../components/steps-box"
import Text from "../../components/text"

const LauterLieblingswoerter = () => {
  const data = useStaticQuery(graphql`
    query {
      set: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/set-1.jpg" }
      ) {
        ...mediumImage
      }
      beispiel01: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/beispiel-1.jpg" }
      ) {
        ...mediumImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/beispiel-2.jpg" }
      ) {
        ...mediumImage
      }
      materialien01: file(
        relativePath: {
          eq: "workshops/lauter-lieblingswoerter/materialien-1.jpg"
        }
      ) {
        ...mediumImage
      }
      materialien02: file(
        relativePath: {
          eq: "workshops/lauter-lieblingswoerter/materialien-2.jpg"
        }
      ) {
        ...mediumImage
      }
      umriss: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/umriss.jpg" }
      ) {
        ...mediumImage
      }
      uebertrag: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/uebertrag.jpg" }
      ) {
        ...mediumImage
      }
      schnitzen01: file(
        relativePath: {
          eq: "workshops/lauter-lieblingswoerter/schnitzen-1.jpg"
        }
      ) {
        ...mediumImage
      }
      schnitzen02: file(
        relativePath: {
          eq: "workshops/lauter-lieblingswoerter/schnitzen-2.jpg"
        }
      ) {
        ...mediumImage
      }
      schnitzen03: file(
        relativePath: {
          eq: "workshops/lauter-lieblingswoerter/schnitzen-3.jpg"
        }
      ) {
        ...mediumImage
      }
      drucken01: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/drucken-1.jpg" }
      ) {
        ...mediumImage
      }
      drucken02: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/drucken-2.jpg" }
      ) {
        ...mediumImage
      }
      drucken03: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/drucken-3.jpg" }
      ) {
        ...mediumImage
      }
      drucken04: file(
        relativePath: { eq: "workshops/lauter-lieblingswoerter/drucken-4.jpg" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="workshop-lauter-lieblingswoerter">
      <Seo
        title="Lauter Lieblingswörter"
        description="In diesem Workshop entstehen gedruckte Postkarten zu Hölderlins Lieblingswörtern. Mit der Linolschnitt-Technik kann dabei jeder ein eigenes Motiv für eine Druckvorlage entwerfen."
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Workshops",
                link: "/workshops",
              },
              {
                title: "Lauter Lieblingswörter",
                link: "/workshops/lauter-lieblingswoerter",
              },
            ]}
          />
          <PageTitle>Lauter Lieblingswörter</PageTitle>
          <Heading level={4} as="p">
            Ein Postkarten-Druckworkshop in Einfacher Sprache für alle ab 10
            Jahren
          </Heading>
          <Paragraph dropcap={true}>
            Manche Wörter haben einen ganz besonderen Klang. <br />
            Manche Wörter rufen Bilder hervor. <br />
            Manche Wörter lösen Gefühle aus. <br />
          </Paragraph>
          <Paragraph>
            Der Dichter Friedrich Hölderlin liebte die Wörter. <br />
            Er spricht sie laut vor sich hin. <br />
            Er nimmt sie mit auf seine Spaziergänge.
            <br />
            Und manche Wörter hat er besonders häufig <br />
            in seine Gedichte eingebaut.
          </Paragraph>
          <Paragraph>
            In diesem Workshop entstehen <br />
            gedruckte Postkarten zu Hölderlins Lieblingswörtern.
            <br />
            Mit der Linolschnitt-Technik kann dabei jeder <br />
            ein eigenes Motiv für eine Druckvorlage entwerfen.
          </Paragraph>
          <Grid columns={[1, 2]} space={[3, 1]}>
            <Image
              image={data.beispiel01.childImageSharp.gatsbyImageData}
              alt="Eine Postkarte mit orange leuchtenden Linien und Hintergrund. Darüber ist mit blauer Farbe eine zweite Schicht gedruckt."
            />
            <Image
              image={data.beispiel02.childImageSharp.gatsbyImageData}
              alt="Ein Tisch mit bunt bedruckten Postkarten."
            />
          </Grid>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            Du brauchst…
          </Heading>
          <Paragraph>
            Für den Workshop haben wir ein Set mit Materialien zusammengestellt.{" "}
            <br />
            Das Set kann man für 5,00 Euro an unserer Museumskasse kaufen.
            <br />
            Darin sind 10 Postkarten
            <br />
            und 2 Linolplatten.
          </Paragraph>
          <Image
            image={data.set.childImageSharp.gatsbyImageData}
            alt="Bedruckte Postkarten mit den Wörtern gehen und Mensch."
          />
          <Paragraph>
            In unserem Shop gibt es auch
            <br />
            Linol-Schnitt-Werkzeuge (7,50 Euro)
            <br />
            Linol-Farbe (3,50 Euro)
            <br />
            und eine Farb-Walze (5,00 Euro)
          </Paragraph>
          <Grid columns={[1, 2]} space={[3, 1]}>
            <Image
              image={data.materialien01.childImageSharp.gatsbyImageData}
              alt="Auf weißen Arbeitsplatten liegen ein Linolschnitt-Werkzeug und einige Buntstifte."
            />
            <Image
              image={data.materialien02.childImageSharp.gatsbyImageData}
              alt="Eine Holzplatte mit Linol-Bestecken, einer blauen Linolplatte und einer Klemmzange."
            />
          </Grid>
          <Paragraph>
            Du kannst dir die Materialien aber auch selbst zusammenstellen.{" "}
          </Paragraph>
          <Paragraph>Außerdem brauchst du:</Paragraph>
          <Stack space={4}>
            <Checkbox space={3} id="checkbox-3">
              <Text as="span" size={4}>
                weißes Papier{" "}
              </Text>
            </Checkbox>
            <Checkbox space={3} id="checkbox-4">
              <Text as="span" size={4}>
                einen Bleistift und Radiergummi
              </Text>
            </Checkbox>
            <Checkbox space={3} id="checkbox-5">
              <Text as="span" size={4}>
                eine feste Unterlage
              </Text>
            </Checkbox>
            <Checkbox space={3} id="checkbox-7">
              <Text as="span" size={4}>
                eine flache Glasplatte oder ein Schneidebrett aus Plastik
              </Text>
            </Checkbox>
          </Stack>
        </Stack>

        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whipser">
              Schritt 1
            </Heading>
            <Heading as="h2" level={2}>
              So gestaltest du deine Druckvorlage
            </Heading>
          </Stack>

          <StepsBox step={1}>
            <Stack space={6}>
              <Paragraph>
                Wähle aus Hölderlins Lieblingswörtern eines aus, das dir
                besonders gut gefällt.
              </Paragraph>
              <Inline space={3}>
                <PaperShred>Freude</PaperShred>
                <PaperShred>Nacht</PaperShred>
                <PaperShred>lassen</PaperShred>
                <PaperShred>Mensch</PaperShred>
                <PaperShred>gehen</PaperShred>
                <PaperShred>Natur</PaperShred>
                <PaperShred>sehen</PaperShred>
                <PaperShred>wollen</PaperShred>
                <PaperShred>Gesang</PaperShred>
                <PaperShred>lieben</PaperShred>
              </Inline>
            </Stack>
          </StepsBox>
          <StepsBox step={2}>
            <Paragraph>
              Überlege dir ein Bild zu dem Wort. <br />
              Was verbindest du mit dem Wort? <br />
              Welches Gefühl löst das Wort in dir aus?
              <br />
              Welches Bild passt dazu?
            </Paragraph>
          </StepsBox>
          <StepsBox step={3}>
            <Stack space={6}>
              <Paragraph>
                Nimm dir ein weißes Papier.
                <br />
                Lege die Linolplatte darauf <br />
                und zeichne den Umriss der Linolplatte ab.
              </Paragraph>
              <Image
                image={data.umriss.childImageSharp.gatsbyImageData}
                alt="Jemand zeichnet den Umriss einer Linolplatte auf ein Papier."
              />
              <Paragraph>Folgendes solltest du dabei beachten:</Paragraph>
              <BulletList size={[3, 3, 4, 5]}>
                <Bullet>
                  Kleine Details lassen sich später nur schwer in die
                  Linolplatte schnitzen. Zeichne deshalb am besten einfache
                  Formen und Umrisse.
                </Bullet>
                <Bullet>
                  Deine Zeichnung wird später spiegelverkehrt gedruckt.
                  Buchstaben und Zahlen musst du deshalb in der Vorlage
                  spiegelverkehrt zeichnen.
                </Bullet>
              </BulletList>
              <Paragraph>
                Du kannst gerne auch mehrere Entwürfe machen. <br />
                Und wenn dir etwas nicht gefällt, <br />
                kannst du es mit dem Radiergummi entfernen
                <br />
                und von Neuem anfangen.
              </Paragraph>
            </Stack>
          </StepsBox>
        </Stack>

        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whipser">
              Schritt 2
            </Heading>
            <Heading as="h2" level={2}>
              So schnitzt du dein Motiv aus der Druckplatte
            </Heading>
          </Stack>
          <StepsBox step={1}>
            <Stack space={6}>
              <Paragraph>
                Bist du mit deinem Entwurf zufrieden? <br />
                Dann kannst du dein Bild mit Bleistift auf die Linolplatte
                übertragen.
              </Paragraph>
              <Image
                image={data.uebertrag.childImageSharp.gatsbyImageData}
                alt="Jemand zeichnet den Umriss einer Linolplatte auf ein Papier."
              />
            </Stack>
          </StepsBox>
          <StepsBox step={2}>
            <Stack space={6}>
              <Paragraph>
                Nun geht es ans Schnitzen!
                <br />
                Dafür brauchst du die Linolplatte und das Schnitt-Werkzeug.
                <br />
                Lege deine Linolplatte auf eine feste Unterlage.
                <br />
                Die Linolplatte sollte nicht wegrutschen.
                <br />
                Mit dem Schnitt-Werkzeug schnitzt du nun dein Motiv aus.
              </Paragraph>
              <Image
                image={data.schnitzen01.childImageSharp.gatsbyImageData}
                alt="Jemand schnitzt mit der rechten Hand eine Figur in eine Linolplatte. Mit der linken Hand hält er die Platte fest."
              />
              <Paragraph>
                Die Flächen, die du aus der Linolplatte herausschneidest, <br />
                bleiben später weiß. <br />
                Die anderen Flächen werden farbig.
              </Paragraph>
              <Image
                image={data.schnitzen02.childImageSharp.gatsbyImageData}
                alt="Eine Hand hält eine Postkarte in der Hand. Auf der Karte ist ein gelber Druck vom Hölderlinturm. Darunter steht das Wort 'sehen'."
              />
              <Paragraph>
                Achte darauf, dass du die Hand mit dem Werkzeug <br />
                immer vom Körper wegbewegst. <br />
                So verletzt du dich nicht.
              </Paragraph>
              <Image
                image={data.schnitzen03.childImageSharp.gatsbyImageData}
                alt="Eine Linolplatte mit einem Motiv vom Hölderlinturm. Jemand schnitzt aus einer Linolplatte die Linien einer Pflanze heraus."
              />
            </Stack>
          </StepsBox>
        </Stack>

        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whipser">
              Schritt 3
            </Heading>
            <Heading as="h2" level={2}>
              Jetzt wird gedruckt...
            </Heading>
          </Stack>

          <StepsBox step={1}>
            <Stack space={6}>
              <Paragraph>
                Hast du dein Motiv aus der Linolplatte herausgeschnitzt?
                <br />
                Dann wird es jetzt farbig!
                <br />
                Lege deinen Tisch dafür mit alten Zeitungen aus.
                <br />
                Suche dir eine flache Glasplatte oder ein Schneidebrett aus
                Plastik
                <br />
                und gebe einen Klecks Farbe darauf.
                <br />
                Nimm die Farbwalze oder den Pinsel
                <br />
                und verstreiche die Farbe auf der Platte oder dem Teller.
              </Paragraph>
              <Image
                image={data.drucken01.childImageSharp.gatsbyImageData}
                alt="Eine blau-weiß gefärbte Glasplatte. Jemand fährt mit einer blauen Farbwalze darüber."
              />
            </Stack>
          </StepsBox>
          <StepsBox step={2}>
            <Stack space={6}>
              <Paragraph>
                Nimm mit der Walze oder dem Pinsel etwas Farbe auf <br />
                und bestreiche deine Linolplatte vollflächig mit Farbe.
                <br />
                Nur die ausgeschnitzten Flächen sollten frei bleiben.
              </Paragraph>
              <Image
                image={data.drucken02.childImageSharp.gatsbyImageData}
                alt="Eine blau-grün gefärbte Linolplatte. Jemand fährt mit einer blauen Farbwalze darüber."
              />
            </Stack>
          </StepsBox>
          <StepsBox step={3}>
            <Stack space={6}>
              <Paragraph>
                Jetzt wird gedruckt. <br />
                Dafür brauchst du die Postkarten-Vorlage oder
                <br />
                das feste weiße Papier in Postkarten-Größe.
              </Paragraph>
              <Paragraph>
                Lege deine eingefärbte Linolplatte vorsichtig auf die Postkarte.
                <br />
                Die Ecken sollten genau übereinander liegen.
                <br />
                Drücke die Linolplatte nun fest mit den Handflächen auf das
                Papier.
                <br />
                Du kannst auch eine Teigrolle oder Glasflasche zu Hilfe nehmen.
                <br />
                Das Papier sollte dabei nicht verrutschen!
                <br />
                Lege dafür einen Filz-Untersetzer oder ein festes Geschirr-Tuch
                <br />
                zwischen Platte und Rolle.
              </Paragraph>
              <Image
                image={data.drucken03.childImageSharp.gatsbyImageData}
                alt="Eine grün eingefärbte Linolplatte mit einer Figur. Die Platte liegt auf einer Postkarte mit dem Wort 'Mensch'."
              />
            </Stack>
          </StepsBox>
          <StepsBox step={4}>
            <Paragraph>
              Anschließend kannst du die Platte vorsichtig vom Papier abziehen{" "}
              <br />
              und die Postkarte zum Trocknen legen.
            </Paragraph>
          </StepsBox>
          <StepsBox step={5}>
            <Stack space={6}>
              <Paragraph>
                Jetzt kannst du noch weitere Postkarten bedrucken.
                <br />
                Oder du kannst mit einer zweiten Farbe noch einmal über deine
                erste Postkarte drucken.
                <br />
                Dabei ergeben sich spannende Farb-Mischungen!
                <br />
                Färbe deine Linolplatte dafür einfach noch einmal vollflächig
                ein.
                <br />
                Und drücke die Platte erneut auf die Postkarte.
              </Paragraph>
              <Image
                image={data.drucken04.childImageSharp.gatsbyImageData}
                alt="Eine Reihe bunt bedruckter Postkarten in den Farben rot, hellgrün, dunkelgrün, gelb und orange."
              />
            </Stack>
          </StepsBox>
          <StepsBox step={6}>
            <Paragraph>
              Zum Schluss solltest du die Linolplatte und deine Druckwerkzeuge
              <br />
              wieder gut abwaschen und trocknen lassen.
              <br />
              So kannst du sie immer wieder verwenden.
            </Paragraph>
          </StepsBox>
        </Stack>
      </SectionSpacing>
    </Layout>
  )
}

export default LauterLieblingswoerter
